import React from 'react'
import Layout from '../components/layout'
import Article from '../components/Article'
import Container from '../components/Container'
import MetaTag from '../components/MetaTag'


const PageNotFound = () => {
  return (
    <Layout>
      <Article title="404">
        <Container>
          <p className="mb-4 leading-8">404, page not found!</p>
        </Container>
      </Article>
    </Layout>
        
    )
}

export default PageNotFound

export function Head({ location }) {
  return (
    <MetaTag
      pathname={location.pathname}
      title='404 | ONESEL'
    >
    </MetaTag>
  )
}